import { createRouter, createWebHistory } from 'vue-router'

import Dashboard from '@/pages/dashboard/routes'
import Atpm from '@/pages/atpm/routes'
import User from '@/pages/user/routes'
import Team from '@/pages/team/routes'
import Role from '@/pages/role/routes'
import DataInput from '@/pages/data-input/routes'
import SerialNumber from '@/pages/serial-number/routes'
import Confirmation from '@/pages/confirmation/routes'
import Survey from '@/pages/survey/routes'
import ProcessPln from '@/pages/process-pln/routes'
import PLNMobile from '@/pages/pln-mobile/routes'
import OptionLibrary from '@/pages/option-library/routes'
import Delivery from '@/pages/delivery/routes'
import Installation from '@/pages/installation/routes'
import Troubleshooting from '@/pages/troubleshooting/routes'
import DataProblem from '@/pages/data-problem/routes'
import Warranty from '@/pages/warranty/routes'
import WarrantyTnC from '@/pages/warranty-t&c/routes'
import WarrantyTrx from '@/pages/warranty-trx/routes'
import WarrantyPacket from '@/pages/warranty-packet/routes'
import WarrantySubscribe from '@/pages/warranty-subscribe/routes'
import DealerUser from '@/pages/dealer-user/routes'
import AtpmUser from '@/pages/atpm-user/routes'
// import RewardPromotion from '@/pages/reward-promotion/routes'
import TypeCharger from '@/pages/type-charger/routes'
import AppUser from '@/pages/app-user/routes'
import AppContent from '@/pages/app-content/routes'
import AppTermsAndCondition from '@/pages/app-t&c/routes'
import AppPrivacyPolicy from '@/pages/app-privacy-policy/routes'
import AppBanner from '@/pages/app-banner/routes'
import BydApproval from '@/pages/byd-approval/routes'
import ApprovalChassisNumber from '@/pages/approval-chassisnumber/routes'
import MaterialTambahan from '@/pages/material-tambahan/routes'
import PlnPower from '@/pages/pln-power/routes'
import Bundling from '@/pages/bundling/routes'
import SurveyOffline from '@/pages/task-v2/survey-offline/routes'
import TaskV2 from '@/pages/task-v2/routes'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/app',
      component: () => import('@/components/layouts/MainLayout.vue'),
      children: [
        Dashboard,
        Atpm,
        User,
        Team,
        DataInput,
        SerialNumber,
        Role,
        Confirmation,
        Survey,
        ProcessPln,
        PLNMobile,
        OptionLibrary,
        Delivery,
        Installation,
        Troubleshooting,
        DataProblem,
        Warranty,
        BydApproval,
        ApprovalChassisNumber,
        MaterialTambahan,
        PlnPower,
        Bundling,
        DealerUser,
        AtpmUser,
        // RewardPromotion
        TypeCharger,
        AppUser,
        AppContent,
        AppTermsAndCondition,
        AppPrivacyPolicy,
        AppBanner,
        TaskV2,
        SurveyOffline,
        WarrantyTnC,
        WarrantyTrx,
        WarrantyPacket,
        WarrantySubscribe
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/pages/login/LoginPage.vue')
    },
    { path: '/:pathMatch(.*)*', redirect: '/login' }
  ]
})

export default router
